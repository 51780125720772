import React from "react"
import { Router } from "@reach/router"
import SlikeComponent from "../components/SlikeComponent"

const slike = () => {
  return (
    <Router>
      <SlikeComponent path="/slike/:id" />
    </Router>
  )
}

export default slike
